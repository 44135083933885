import logo from './logo.svg';
import './App.css';
import Allrouter from './Router/Allrouter';
import Navebar from './Navebar';
import Navebar1 from './Navebar1';

function App() {
  return (
    <div className="App">
     
      <Navebar1/>
      <Allrouter/>
    </div>
  );
}

export default App;
