import React from 'react'

const Home = () => {
  return (
    <div>
      
      <h1>Home</h1>
      <h4>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque
        quaerat quam magnam. Expedita maxime explicabo laborum eum sequi! Enim,
        iure! Tenetur eligendi delectus, soluta cupiditate deserunt repudiandae
        ipsa distinctio fugit?
      </h4>
    </div>
  )
}

export default Home
