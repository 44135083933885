import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const User = () => {
  const ids = [1, 2, 3, 4, 5, 6];
  const [data, setData] = useState([]);
  function getdata(){
    fetch("https://reqres.in/api/users")
    .then((response) => response.json())
    .then((res) => {
     console.log(res.data);
      setData(res.data)

    });
  }
  useEffect(() => {
    getdata()
  }, []);

  //console.log(data)

  return (
    <div>
      <h1> User</h1>
      <div>
      {/* {data.map((id)=>(
       // 
        <div>
           
        <Link to={`/user/${id}`} >move to {id}</Link>
        </div>
        

      ))} */}
      {
        data.map((item)=>(
          <div>
            <h3>{item.first_name}</h3>
            <img width="100px" src={item.avatar} alt={item.id}/>
            <div>
           
           <Link to={`/user/${item.id}`} >mare details {item.id}</Link>
           </div>
          </div>
        ))
      }
   
      </div>
    </div>
  );
};

export default User;
