import React from 'react'
import {Routes,Route} from "react-router-dom"
import About from './About'
import Contact from './Contact'
import Home from './Home'
import User from './User'
import Userpage from './Userpage'

const Allrouter = () => {
  return (
    <div>
        <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/user" element={<User/>} />
        <Route path="/user/:user_id" element={<Userpage/>} />
        </Routes>
      
    </div>
  )
}

export default Allrouter
