import React from "react";

const About = () => {
  return (
    <div>
      <h1>About</h1>
      <h2>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque
        quaerat quam magnam. Expedita maxime explicabo laborum eum sequi! Enim,
        iure! Tenetur eligendi delectus, soluta cupiditate deserunt repudiandae
        ipsa distinctio fugit?
      </h2>
    </div>
  );
};

export default About;
