import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const Userpage = () => {
  const param = useParams();
  const [data, setData] = useState({});
  function getdata() {
    fetch(`https://reqres.in/api/users/${param.user_id}`)
      .then((response) => response.json())
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      });
  }
  useEffect(() => {
    getdata();
  }, []);
  return (
    <div>
      <h1>user single page</h1>
      <div style={{display:"flex",gap:"2rem",justifyContent:"center",textAlign:"left",alignItems:"center"}}>
        <div>
          <img width="400px" src={data.avatar} />
        </div>
        <div>
          <h3>user {param.user_id}</h3>
          <h3>
            {" "}
            Name:{data.first_name} {data.last_name}
          </h3>
          <h3> Email:{data.email}</h3>
        </div>
      </div>
      <div>
        <Link to="/user"> go back</Link>
      </div>
    </div>
  );
};

export default Userpage;
