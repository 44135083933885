import React from "react";
import { Link } from "react-router-dom";
const link=[
    {
        to:"/",
        title:"Home"
    },
    {
        to:"/about",
        title:"About"
    },
    {
        to:"/contact",
        title:"Contact"
    },
    {
        to:"/user",
        title:"User"
    }
]

const Navebar1 = () => {
  return (
    <div style={{dispaly:"flex", justifyContent:"space-around"}}>
      {/* <Link to="/">Home</Link>
      <Link to="/about">About</Link> */}
      {link.map((item)=>(
        <Link to={item.to}>{item.title}</Link>
      ))}
    </div>
  );
};

export default Navebar1;
